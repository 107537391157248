/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import type {
  DoctorsComments as DoctorsCommentsTypes,
  PatientDocuments as DocumentTypes,
} from '../../../types';
import style from './Record.module.scss';
import { patientSliceSelector } from '../patientOverviewSlice';
import { Loader } from '../../../components/atoms';
import { getFile } from '../../../utils/apiCalls';

const cx = classNames.bind(style);

type RecordTypes = {
  mainDiseaseLabels: string;
  documents: DocumentTypes[];
  doctorsComments: DoctorsCommentsTypes[];
};

export function Record({ mainDiseaseLabels, documents, doctorsComments }: RecordTypes): JSX.Element {
  const pageClasses = cx({ Record: true });
  const GreenHeading = cx('GreenHeading');
  const TextLabel = cx('TextLabel');
  const { patientInfo, loadPatient } = useSelector(patientSliceSelector);

  const previousDoctorsLabels = (labels: string) => {
    if (!labels) {
      return [];
    }

    const germanLabels = labels
      .replace('familyDoctor', 'Hausärzte / Hausärztinnen')
      .replace('alternativePractitioner', 'Heilpraktier:innen')
      .replace('orthopedist', 'Orthopäden / Orthopädinnen')
      .replace('neurologist', 'Neurologen / Neurologinnen')
      .replace('gynecologist', 'Gynäkologen / Gynäkologinnen')
      .replace('eyeSpecialist', 'Augenheilkundler:innen')
      .replace('psychotherapist', 'Psychotherapeuten / Psychotherapeutinnen')
      .replace('internist', 'Internisten / Internistinnen')
      .replace('anesthesiologist', 'Anästhesisten / Anästhesistinnen')
      .replace('dermatologist', 'Dermatologen / Dermatologinnen')
      .replace('otorhinolaryngologist', 'HNO-Ärzte / HNO-Ärztinnen')
      .replace('chiropractor', 'Chiropraktiker:innen')
      .replace('surgeon', 'Chirurgen / Chirurginnen')
      .replace('psychiatrist', 'Psychiater:innen')
      .replace('urologist', 'Urologen / Urologinnen')
      .replace('radiologist', 'Radiologen / Radiologinnen');
    const array = germanLabels.split(', ');

    const FinalArray = array.filter((element) => {
      if (element === 'other') {
        return false;
      }

      return true;
    });

    return FinalArray;
  };

  const TreatmentMethodsLabels = (labels: string) => {
    if (!labels) {
      return [];
    }
    const germanLabels = labels
      .replace('none', 'Keine')
      .replace('injection', 'Injektionen')
      .replace('acupuncture', 'Akupunktur')
      .replace('meditation', 'Meditation')
      .replace('cure', 'Kur')
      .replace('baths', 'Bäder')
      .replace('physiotherapy', 'Krankengymnastik')
      .replace('psychotherapy', 'Psychotherapie')
      .replace('hypnosis', 'Hypnose')
      .replace('massages', 'Massagen')
      .replace('homeopathy', 'Homöopathie')
      .replace('medication', 'Medikamente');
    const array = germanLabels.split(', ');

    const FinalArray = array.filter((element) => {
      if (element === 'other') {
        return false;
      }
      if (element === 'further') {
        return false;
      }

      return true;
    });

    return FinalArray;
  };

  const otherDiseasesLabels = () => {
    if (!patientInfo?.secondaryDiagnosis) {
      return [];
    }
    const data = [];
    if (patientInfo.secondaryDiagnosis.movementDiseases) {
      data.push(...patientInfo.secondaryDiagnosis.movementDiseases.split(','));
    }
    if (patientInfo.secondaryDiagnosis.movementDiseasesOther) {
      data.push(...patientInfo.secondaryDiagnosis.movementDiseasesOther.split(','));
    }
    if (patientInfo.secondaryDiagnosis.respiratoryDiseases) {
      data.push(...patientInfo.secondaryDiagnosis.respiratoryDiseases.split(','));
    }
    if (patientInfo.secondaryDiagnosis.respiratoryDiseasesOther) {
      data.push(...patientInfo.secondaryDiagnosis.respiratoryDiseasesOther.split(','));
    }

    if (patientInfo.secondaryDiagnosis.cardiovascularDiseases) {
      data.push(...patientInfo.secondaryDiagnosis.cardiovascularDiseases.split(','));
    }
    if (patientInfo.secondaryDiagnosis.cardiovascularDiseasesOther) {
      data.push(...patientInfo.secondaryDiagnosis.cardiovascularDiseasesOther.split(','));
    }
    if (patientInfo.secondaryDiagnosis.gastrointestinalDiseases) {
      data.push(...patientInfo.secondaryDiagnosis.gastrointestinalDiseases.split(','));
    }
    if (patientInfo.secondaryDiagnosis.gastrointestinalDiseasesOther) {
      data.push(...patientInfo.secondaryDiagnosis.gastrointestinalDiseasesOther.split(','));
    }
    if (patientInfo.secondaryDiagnosis.neurologicalDiseases) {
      data.push(...patientInfo.secondaryDiagnosis.neurologicalDiseases.split(','));
    }
    if (patientInfo.secondaryDiagnosis.neurologicalDiseasesOther) {
      data.push(...patientInfo.secondaryDiagnosis.neurologicalDiseasesOther.split(','));
    }
    if (patientInfo.secondaryDiagnosis.psychiatricDiseases) {
      data.push(...patientInfo.secondaryDiagnosis.psychiatricDiseases.split(','));
    }
    if (patientInfo.secondaryDiagnosis.infectiousDiseases) {
      data.push(...patientInfo.secondaryDiagnosis.infectiousDiseases.split(','));
    }
    if (patientInfo.secondaryDiagnosis.infectiousDiseasesOther) {
      data.push(...patientInfo.secondaryDiagnosis.infectiousDiseasesOther.split(','));
    }
    if (patientInfo.secondaryDiagnosis.dermatology) {
      data.push(...patientInfo.secondaryDiagnosis.dermatology.split(','));
    }
    if (patientInfo.secondaryDiagnosis.dermatologyOther) {
      data.push(...patientInfo.secondaryDiagnosis.dermatologyOther.split(','));
    }

    const germanLabels = data.map((element) => element
      .replace('acneInversa', 'Akna Inversa')
      .replace('hyperhidrosis', 'Hyperhidrose (übermäßiges Schwitzen)')
      .replace('psoriasis', 'Psoriasis (Schuppenflechte)')
      .replace('neurodermatitis', 'Neurodermitis')
      .replace('urticaria', 'Utrikaria (Nesselsucht)')
      .replace('pruritus', 'Pruritus (Juckreiz)')
      .replace('osteoarthritis', 'Arthrose')
      .replace('gout', 'Gicht')
      .replace('rheumatoidArthritis', 'rheumatoide Arthritis')
      .replace('fibromyalgia', 'Fibromyalgie')
      .replace('bronchialAsthma', 'Asthma bronchiale')
      .replace('copd', 'COPD (chronisch-obstruktive Lungenerkrankung')
      .replace('pneumonia', 'Lungenentzündung in den letzten 12 Monaten')
      .replace('highBloodPressure', 'Bluthochdruck')
      .replace('heartFailure', 'Herzinsuffizienz')
      .replace('heartAttack', 'Herzinfarkt oder Schlaganfall')
      .replace('bloodClottingDisorder', 'Blutgerinnungsstörung')
      .replace('cardiacArrhythmia', 'Herzrythmusstörung')
      .replace('coronaryHeartDisease', 'koronare Herzkrankheit (KHK)')
      .replace('thrombosis', 'Thrombose oder Embolie')
      .replace('crohnsDisease', 'orbus Crohn')
      .replace('irritableBowelSyndrome', 'Reizdarmsyndrom')
      .replace('cachexia', 'Kachexie (Abmagerung)')
      .replace('metabolicDisorders', 'Stoffwechselstörungen')
      .replace('ulcerativeColitis', 'Colitis ulcerosa')
      .replace('gastritis', 'Magenschleimhautentzündung (Gastritis)')
      .replace('diabetesMellitus', 'Diabetes mellitus')
      .replace('chronicHeadache', 'chronische Kopfschmerzen')
      .replace('epilepsy', 'Epilepsie / Krampfanfälle')
      .replace('tourettesSyndrome', 'Tourette-Syndrom')
      .replace('parkinsonsDisease', 'Morbus Parkinson')
      .replace('paraplegia', 'Paraplegie/Lähmungserscheinungen')
      .replace('migraine', 'Migräne')
      .replace('multipleSclerosis', 'multipleSclerosis')
      .replace('adhs', 'AD(H)S')
      .replace('polyneuropathy', 'Polyneuropathie')
      .replace('anxeityDisorder', 'Angststörungen')
      .replace('adjustmentDisorder', 'Anpassungsstörung')
      .replace('depression', 'Depressionen')
      .replace('postTraumaticStressDisorder', 'posttraumatische Belastungsstörung (PTBS)')
      .replace('psychoses', 'Psychosen')
      .replace('psychosesFamily', 'Psychosen innerhalb der Verwandschaft')
      .replace('sleepDisorder', 'Schlafstörungen')
      .replace('addiction', 'Suchterkrankung')
      .replace('hepatits', 'Hepatitis')
      .replace('tuberculosis', 'Tuberkulose')
      .replace('aids', 'HIV / AIDS')
      .replace('lymeDisease', 'Borreliose'));

    const FinalArray = germanLabels.filter((element) => {
      if (element === ' other') {
        return false;
      }
      if (element === 'Sonstiges') {
        return false;
      }

      return true;
    });

    return FinalArray;
  };

  const gynecologicalAnamnesisLabels = (labels: string) => {
    const germanLabels = labels
      .replace('pregnancy', 'Schwangerschaft')
      .replace('pregnancyInPlanning', 'Schwangerschaft in Planung')
      .replace('breastfeed', 'Stillen')
      .replace('endometriosis', 'Endometriose')
      .replace('bladderSpasms', 'Blasenkrämpfe');
    const array = germanLabels.split(', ');
    if (array.length === 0) {
      return [germanLabels];
    }

    return array.filter((element: string) => element !== 'other');
  };

  const getMimeType = (fileName: string) => {
    const splitFilename = fileName.split('.');
    const fileExtension = splitFilename[splitFilename.length - 1];
    let mimeType = `image/${fileExtension}`;
    if (fileExtension === 'pdf') {
      mimeType = 'application/pdf';
    }

    return mimeType;
  };

  const showFile = async (fileId: number, fileName: string): Promise<void> => {
    const fileResponse = await getFile(fileId);
    if (fileResponse.error) {
      toast.error('Etwas ist schief gelaufen.');

      return;
    }

    const base64Filedata = fileResponse.data.file;
    const byteCharacters = atob(base64Filedata);
    const byteNumbers = [];

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteNumbers);

    const file = new Blob([byteArray], { type: `${getMimeType(fileName)};base64` });

    const fileURL = URL.createObjectURL(file);

    window.open(fileURL, '_blank');
    URL.revokeObjectURL(fileURL);
  };

  const downloadFile = async (fileId: number, fileName: string): Promise<void> => {
    const fileResponse = await getFile(fileId);
    if (fileResponse.error) {
      toast.error('Etwas ist schief gelaufen.');

      return;
    }

    const base64Filedata = fileResponse.data.file;

    const a = document.createElement('a');
    a.href = `data:${getMimeType(fileName)};base64,${base64Filedata}`;
    a.download = fileName;
    a.click();
    a.remove();
  };

  useEffect(() => {
    otherDiseasesLabels();
  }, []);

  const getStatusText = (status: any): string => {
    if (status === 'last3months') {
      return 'vor 3 Monaten';
    } if (status === 'last3to6months') {
      return 'vor 3 bis 6 Monaten';
    } if (status === 'last6to12months') {
      return 'vor 6 bis 12 Monaten';
    } if (status === 'over1year') {
      return 'vor über 1 Jahr';
    }
    return '';
  };

  const renderDoctorsComments = () => (
    doctorsComments.map((element: DoctorsCommentsTypes, index: number) => (
      <div className={style.RemarkTextContainer} key={index}>
        <p className={TextLabel}>
          {new Date(element.createdAt)
            .toLocaleString('de-DE', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
            .replace('-', '.')}
        </p>
        <p className={style.FreeText}>{element.text}</p>
      </div>
    ))
  );

  const renderMaindiseaseLabels = () => (
    mainDiseaseLabels.split(',').map((element: string, index: number) => (
      <li className={TextLabel} key={index}>
        {element}
      </li>
    ))
  );

  const renderPreviousDoctorsLabels = () => (
    previousDoctorsLabels(patientInfo.firstPresentation.previousDoctors).map((element, index) => (
      <li className={TextLabel} key={index}>
        {element}
      </li>
    )));

  const renderTreatmentMethodsLabels = () => (
    patientInfo?.therapy?.otherTreatmentMethods && (
      patientInfo?.additionalQuestions?.previousTreatments
   && (TreatmentMethodsLabels(patientInfo.therapy.otherTreatmentMethods).map((element, index) => (
     <li className={TextLabel} key={index}>
       {element}
     </li>
   ))))
  );

  const renderDocuments = () => (
    documents
    && (documents.map((element: DocumentTypes, index: number) => (
      <div className={style.DocumentsRowContainer} key={index}>
        <div>
          <p className={style.DocumentsTextLabel}>
            {new Date(element.created)
              .toLocaleString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
              .replace('-', '.')}
          </p>
        </div>
        <div>
          <p className={style.DocumentsTextBold}>{element.filename}</p>
        </div>
        {element.thumbnail && (
          <div>
            <img src={`data:image/png;base64,${element.thumbnail}`} className={style.imgClass} />
          </div>
        )}
        {!element.thumbnail && <div className={style.emptyThumbnail}></div>}
        <div>
          {element.thumbnail && (
            <p
              onClick={(event) => {
                event.preventDefault();
                showFile(element.id, element.filename);
              }}
              className={style.showImgText}
            >
                Anzeigen
            </p>
          )}
          {!element.thumbnail && (
            <p
              onClick={(event) => {
                event.preventDefault();
                downloadFile(element.id, element.filename);
              }}
              className={style.showImgText}
            >
                Download
            </p>
          )}
        </div>
      </div>
    ))));

  const renderGynecologicalAnamnesisLabels = () => (
    patientInfo?.secondaryDiagnosis?.gynecologicalAnamnesis
  && (gynecologicalAnamnesisLabels(patientInfo.secondaryDiagnosis.gynecologicalAnamnesis).map(
    (element, index) => (
      <li className={style.SecondaryDiagnosisBoldText} key={index}>
        {element}
      </li>
    ),
  )));

  const renderOtherDiseasesLabels = () => (
    otherDiseasesLabels().map((element, index) => (
      <li className={style.SecondaryDiagnosisBoldText} key={index}>
        {element}
      </li>
    )));

  return (
    <div className={pageClasses}>
      {loadPatient ? <Loader /> : (<div className={style.MainContainer}>
        <div>
          <h1 className={GreenHeading}>Persönliche daten</h1>
          <div className={style.personalDataContainer}>
            <div>
              <div>
                <p className={style.PersonalDataTextLabel}>Nachname</p>
                <p className={style.PersonalDataTextBold}>{patientInfo?.lastname}</p>
              </div>
              <div>
                <p className={style.PersonalDataTextLabel}>Vorname</p>
                <p className={style.PersonalDataTextBold}>{patientInfo?.firstname}</p>
              </div>
              <div>
                <p className={style.PersonalDataTextLabel}>Geburtsdatum</p>
                <p className={style.PersonalDataTextBold}>
                  {patientInfo?.birthday
                    && new Date(patientInfo?.birthday)
                      .toLocaleString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })
                      .replace('-', '.')}
                </p>
              </div>
              {patientInfo?.mobile !== '' && (
                <div>
                  <p className={style.PersonalDataTextLabel}>Telefon (mobil)</p>
                  <p className={style.PersonalDataTextBold}>{patientInfo?.mobile}</p>
                </div>
              )}
              {patientInfo?.landline !== '' && (
                <div>
                  <p className={style.PersonalDataTextLabel}>Telefon (Festnetz)</p>
                  <p className={style.PersonalDataTextBold}>{patientInfo?.landline}</p>
                </div>
              )}
              <div>
                <p className={style.PersonalDataTextLabel}>E-Mail</p>
                <p className={style.PersonalDataTextBold}>{patientInfo?.email}</p>
              </div>
            </div>
            <div>
              <div className={style.ColumnContainer}>
                <p className={style.PersonalDataTextLabel}>Geschlecht</p>

                {patientInfo?.sex === 'male' && <p className={style.PersonalDataTextBold}>männlich</p>}
                {patientInfo?.sex === 'female' && <p className={style.PersonalDataTextBold}>weiblich</p>}
                {patientInfo?.sex === 'other' && <p className={style.PersonalDataTextBold}>andere</p>}
              </div>
              {patientInfo?.title && (
                <div>
                  <p className={style.PersonalDataTextLabel}>Titel</p>
                  <p className={style.PersonalDataTextBold}>
                    {patientInfo.title.replace('doctor', 'Dr.').replace('professor', 'Prof.')}
                  </p>
                </div>
              )}
              <div>
                <p className={style.PersonalDataTextLabel}>Anschrift</p>
                <p className={style.PersonalDataTextBold}>
                  {patientInfo?.address[0]?.street} {patientInfo?.address[0]?.houseNumber},   {patientInfo?.address[0]?.postcode} {patientInfo?.address[0]?.city}, {patientInfo?.address[0]?.country
                      && patientInfo?.address[0]?.country
                        .replace('germany', 'Deutschland')
                        .replace('switzerland', 'Schweiz')
                        .replace('poland', 'Polen')
                        .replace('denmark', 'Dänemark')}
                </p>
              </div>
            </div>
          </div>
        </div>
        {doctorsComments && doctorsComments.length !== 0 && (
          <div>
            <h1 className={GreenHeading}>Kommentar für die behandelnden Ärzte</h1>
            {renderDoctorsComments()}
          </div>
        )}
        {patientInfo.questionnaire
        && <>
          <div>
            <h1 className={GreenHeading}>Fragebogen</h1>
            <div className={style.questionnaireContainer}>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Vorausgehende Cannabistherapie</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].isAlreadyCannabisPatient === true ? 'ja' : 'nein'}
                </p>
              </div>
              {patientInfo?.questionnaire[0].isAlreadyCannabisPatient === true
              && <>
                <div className={style.questionnaireRowContainer}>
                  <p className={TextLabel}>Arzt, der in den letzten 12 Monaten ein Rezept ausgestellt hat</p>
                  <p className={TextLabel}>
                    {patientInfo?.questionnaire[0].recipeDoctor}
                  </p>
                </div>
                <div className={style.questionnaireRowContainer}>
                  <p className={TextLabel}>Datum letztes Cannabisrezept</p>
                  <p className={TextLabel}>
                    {new Date(patientInfo?.questionnaire[0].lastCannabisRecipeDate)
                      .toLocaleString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })
                      .replace('-', '.')}
                  </p>
                </div>
              </>
              }
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Erkrankung, wegen der Cannabistherapie in Erwägung gezogen wird</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].mainDisease}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Erkrankung diagnostiziert von</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].diagnosticDoctor}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Patient leidet unter Erkrankung seit</p>
                <p className={TextLabel}>
                  {new Date(patientInfo?.questionnaire[0].sinceDisease)
                    .toLocaleString('de-DE', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })
                    .replace('-', '.')}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Bisherige Therapien / Medikamente</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].therapiesMedicationCondition}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Wann zuletzt bei Arzt oder Therapeuten</p>
                <p className={TextLabel}>
                  {getStatusText(patientInfo?.questionnaire[0].lastVisitDoctor)}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Beschwerden seitdem grundlegend geändert?</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].hasSymptomsChanged === true && `Ja: ${patientInfo?.questionnaire[0].symptomsChanged}`}
                  {patientInfo?.questionnaire[0].hasSymptomsChanged === false && 'nein'}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Nimmt aktuell Medikamente?</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].takeOtherMedicines === true && `Ja: ${patientInfo?.questionnaire[0].otherMedicines}`}
                  {patientInfo?.questionnaire[0].takeOtherMedicines === false && 'nein'}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Weitere Krankheiten/Diagnosen</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].hasOtherDisease === true && `Ja: ${patientInfo?.questionnaire[0].otherDisease}`}
                  {patientInfo?.questionnaire[0].hasOtherDisease === false && 'nein'}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Psychose, Schizophrenie, Manie oder Bipolare Erkrankung</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].hasPsychiatricDisorder === true ? 'ja' : 'nein'}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Einschränkung der Leber oder Nierenfunktion</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].hasLiverDisorder === true ? 'ja' : 'nein'}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Herz-Kreislauf- Erkrankung</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].hasCardiovascularDisease === true ? 'ja' : 'nein'}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Panikattacken oder einer Angststörung</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].hasPanicAttacks === true ? 'ja' : 'nein'}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Persönlichkeitsstörung</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].hasPersonalityDisorder === true && `Ja: ${patientInfo?.questionnaire[0].personalityDisorder}`}
                  {patientInfo?.questionnaire[0].hasPersonalityDisorder === false && 'nein'}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Suchterkrankung</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].hasAddictionDisorder === true ? 'ja' : 'nein'}
                </p>
              </div>
              <div className={style.questionnaireRowContainer}>
                <p className={TextLabel}>Schwanger / Stillzeit</p>
                <p className={TextLabel}>
                  {patientInfo?.questionnaire[0].isPregnant === true ? 'ja' : 'nein'}
                </p>
              </div>
            </div>
          </div>
        </>
        }
        {!patientInfo.questionnaire
        && <>
          <div>
            <div className={style.SicknessMedicationContainer}>
              <div>
                <h1 className={GreenHeading}>Erkrankung</h1>
                <div className={style.SicknessContainer}>
                  <div className={style.SicknessRowContainer}>
                    <p className={TextLabel}>Beschwerden</p>
                    <div>
                      <ul className={style.ListItems}>
                        {renderMaindiseaseLabels()}
                      </ul>
                    </div>
                  </div>
                  <div className={style.SicknessRowContainer}>
                    <p className={TextLabel}>Erkrankungsbezeichnung</p>
                    <p className={TextLabel}>{patientInfo?.firstPresentation?.designation}</p>
                  </div>
                  <div className={style.SicknessRowContainer}>
                    <p className={TextLabel}>Erkrankt seit</p>
                    <p className={TextLabel}>
                      {patientInfo?.firstPresentation?.sinceDisease
                      && patientInfo?.firstPresentation?.sinceDisease
                      && new Date(patientInfo?.firstPresentation?.sinceDisease)
                        .toLocaleString('de-DE', {
                          month: 'long',
                          year: 'numeric',
                        })
                        .replace('-', '.')}
                    </p>
                  </div>
                  <div className={style.SicknessRowContainer}>
                    <p className={TextLabel}>Ärtztliche Diagnose</p>
                    <p className={TextLabel}>
                      {patientInfo?.firstPresentation?.hasMainDiagnosis === true && 'ja'}
                      {patientInfo?.firstPresentation?.hasMainDiagnosis === false && 'nein'}
                    </p>
                  </div>
                  <div className={style.SicknessRowContainer}>
                    <p className={TextLabel}>Wegen Symptomatik aufgesucht</p>

                    <div className={style.ColumnContainer}>
                      <ul className={style.ListItems}>
                        {patientInfo?.firstPresentation?.previousDoctors
                        && renderPreviousDoctorsLabels()}
                        {patientInfo?.firstPresentation?.previousDoctorsOther && (
                          <li className={TextLabel}>{patientInfo?.firstPresentation?.previousDoctorsOther}</li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className={style.SicknessRowContainer}>
                    <p className={TextLabel}>Häufigkeit der Beschwerden</p>
                    <p className={TextLabel}>
                      {patientInfo?.firstPresentation?.frequentComplaints
                      && patientInfo?.firstPresentation?.frequentComplaints
                        .replace('permanent', 'Dauernd')
                        .replace('daily', 'Täglich')
                        .replace('weekly', 'Wöchentlich')
                        .replace('monthly', 'Monatlich')
                        .replace('lessFrequently', 'Seltener')}
                    </p>
                  </div>
                  <div className={style.SicknessRowContainer}>
                    <p className={TextLabel}>Verwandte leiden an ähnlichen Erkrankungen</p>
                    <p className={TextLabel}>
                      {patientInfo?.firstPresentation?.relatives === true && 'ja'}
                      {patientInfo?.firstPresentation?.relatives === false && 'nein'}
                    </p>
                  </div>
                  <div className={style.SicknessRowContainer}>
                    <p className={TextLabel}>Stationär behandelt</p>
                    <p className={TextLabel}>
                      {patientInfo?.therapy?.hasInpatientTreatments === true && 'ja'}
                      {patientInfo?.therapy?.hasInpatientTreatments === false && 'nein'}
                    </p>
                  </div>
                  <div className={style.SicknessRowContainer}>
                    <p className={TextLabel}>Operiert</p>
                    <p className={TextLabel}>
                      {patientInfo?.therapy?.hasOperations === true && 'ja'}
                      {patientInfo?.therapy?.hasOperations === false && 'nein'}
                    </p>
                  </div>
                  <div className={style.SicknessRowContainer}>
                    <p className={TextLabel}>Versuchte Behandlungsmethoden</p>

                    {patientInfo?.therapy?.otherTreatmentMethods && (
                      <div className={style.ColumnContainer}>
                        <ul className={style.ListItems}>
                          {renderTreatmentMethodsLabels()}

                          {patientInfo?.therapy?.otherTreatmentMethodsOther && (
                            <li className={TextLabel}>{patientInfo?.therapy?.otherTreatmentMethodsOther}</li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h1 className={GreenHeading}>Medikation</h1>
                <div className={style.MedicationContainer}>
                  <div className={style.MedicationRowContainer}>
                    <p className={TextLabel}>Medikamente wegen Haupterkrankung</p>
                    <p className={TextLabel}>
                      {patientInfo?.therapy?.takeOtherMedicines === true && `${patientInfo?.therapy?.otherMedicines}`}
                      {patientInfo?.therapy?.takeOtherMedicines === false && 'nein'}
                    </p>
                  </div>
                  <div className={style.MedicationRowContainer}>
                    <p className={TextLabel}>Medikamente wegen anderer Erkrankung</p>
                    <p className={TextLabel}>
                      {patientInfo?.therapy?.takeOtherMedicinesOtherDiseases === true
                      && `${patientInfo?.therapy?.otherMedicinesOtherDiseases || ''}`}
                      {patientInfo?.therapy?.takeOtherMedicinesOtherDiseases === false && 'nein'}
                    </p>
                  </div>
                  <div className={style.MedicationRowContainer}>
                    <p className={TextLabel}>Wirksamkeit der Medikamente</p>
                    {patientInfo?.therapy?.medicationalEfficacy
                    && patientInfo?.therapy?.medicationalEfficacy[0]?.effectiveness && (
                      <p className={TextLabel}>{patientInfo?.therapy?.medicationalEfficacy[0]?.effectiveness}</p>
                    )}
                  </div>
                  <div className={style.MedicationRowContainer}>
                    <p className={TextLabel}>Andere medikamentöse Therapien</p>
                    <p className={TextLabel}>
                      {patientInfo?.therapy?.triedOtherDrugTherapies === true && `${patientInfo?.therapy?.otherDrugTherapies}`}
                      {patientInfo?.therapy?.triedOtherDrugTherapies === false && 'nein'}
                    </p>
                  </div>
                  <div className={style.MedicationRowContainer}>
                    <p className={TextLabel}>THC-haltiges Cannabis ausprobiert</p>
                    <p className={TextLabel}>
                      {patientInfo?.cannabisTherapy?.triedThcCannabis === true && 'ja'}
                      {patientInfo?.cannabisTherapy?.triedThcCannabis === false && 'nein'}
                    </p>
                  </div>
                  <div className={style.MedicationRowContainer}>
                    <p className={TextLabel}>Bereits mit Cannabis behandelt</p>
                    <p className={TextLabel}>
                      {patientInfo?.cannabisTherapy?.hasMedicalCannabisTreatment === true
                      && `ja: ${patientInfo?.cannabisTherapy?.preparation || ''}`}
                      {patientInfo?.cannabisTherapy?.hasMedicalCannabisTreatment === false && 'nein'}
                    </p>
                  </div>
                  <div className={style.MedicationRowContainer}>
                    <p className={TextLabel}>Zustand durch Cannabis-Therapie verbessert</p>
                    <p className={TextLabel}>
                      {patientInfo?.cannabisTherapy?.hasImprovedCondition === true && 'ja'}
                      {patientInfo?.cannabisTherapy?.hasImprovedCondition === false && 'nein'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h1 className={GreenHeading}>Nebendiagnosen</h1>
            <div className={style.SecondaryDiagnosisContainer}>
              <div className={style.SecondaryDiagnosisRowContainer}>
                <p className={style.SecondaryDiagnosisTextLabel}>Weitere Krankheiten</p>
                <div className={style.ColumnContainer}>
                  <ul className={style.ListItems}>
                    {renderOtherDiseasesLabels()}
                  </ul>
                </div>
              </div>
              <div className={style.SecondaryDiagnosisRowContainer}>
                <p className={style.SecondaryDiagnosisTextLabel}>Tumor- / Krebserkrankung</p>
                <p className={style.SecondaryDiagnosisBoldText}>
                  {patientInfo?.secondaryDiagnosis?.cancerousDisease === true
                  && `ja: ${patientInfo?.secondaryDiagnosis?.cancerous}`}
                  {patientInfo?.secondaryDiagnosis?.cancerousDisease === false && 'nein'}
                </p>
              </div>
              <div className={style.SecondaryDiagnosisRowContainer}>
                <p className={style.SecondaryDiagnosisTextLabel}>Gynäkologische Anamnese</p>
                <div className={style.ColumnContainer}>
                  <ul className={style.ListItems}>
                    {renderGynecologicalAnamnesisLabels()}
                    {patientInfo?.secondaryDiagnosis?.gynecologicalAnamnesisOther && (
                      <li className={style.SecondaryDiagnosisBoldText}>
                        {patientInfo?.secondaryDiagnosis?.gynecologicalAnamnesisOther}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={style.SicknessMedicationContainer}>
              <div>
                <h1 className={GreenHeading}>Beschwerden & Schmerzen</h1>
                <div className={style.ComplaintsContainer}>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Beschwerden durchschnittlich (letzte 4 Wochen)</p>
                    {patientInfo?.complaints && <p className={TextLabel}>{patientInfo?.complaints[0]?.average}</p>}
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Beschwerden maximal (letzte 4 Wochen)</p>
                    {patientInfo?.complaints && <p className={TextLabel}>{patientInfo?.complaints[0]?.maximum}</p>}
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Beschwerden mindestens (letzte 4 Wochen)</p>
                    {patientInfo?.complaints && <p className={TextLabel}>{patientInfo?.complaints[0]?.minimal}</p>}
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>So stark dürfen Beschwerden sein</p>
                    {patientInfo?.complaints && <p className={TextLabel}>{patientInfo?.complaints[0]?.maximumStrength}</p>}
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Schmerzintensität</p>
                    {patientInfo?.additionalQuestions && <p className={TextLabel}>{patientInfo?.additionalQuestions?.pains}</p>}
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Schlafstörungen durch Schmerzen</p>
                    <p className={TextLabel}>
                      {patientInfo?.additionalQuestions?.hasSleepDisorder === true && 'ja'}
                      {patientInfo?.additionalQuestions?.hasSleepDisorder === false && 'nein'}
                    </p>
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Schmerzen behandelt durch</p>

                    {patientInfo?.additionalQuestions && (
                      <div className={style.ColumnContainer}>
                        <ul className={style.ListItems}>
                          {renderTreatmentMethodsLabels()}
                          {patientInfo?.additionalQuestions?.previousTreatmentsFurther && (
                            <li className={TextLabel}>{patientInfo?.additionalQuestions?.previousTreatmentsFurther}</li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h1 className={GreenHeading}>Beeinträchtigung Lebensqualität</h1>
                <div className={style.LifeQualityContainer}>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Stimmung</p>
                    <p className={TextLabel}>{patientInfo?.lifeQuality && patientInfo?.lifeQuality[0]?.mood}</p>
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Lebensfreude</p>
                    <p className={TextLabel}>{patientInfo?.lifeQuality && patientInfo?.lifeQuality[0]?.joyOfLife}</p>
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Allgemeine Aktivität / körperliche Belastbarkeit</p>
                    <p className={TextLabel}>{patientInfo?.lifeQuality && patientInfo?.lifeQuality[0]?.generalActivity}</p>
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Appetit</p>
                    <p className={TextLabel}>{patientInfo?.lifeQuality && patientInfo?.lifeQuality[0]?.appetite}</p>
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Sexualität</p>
                    <p className={TextLabel}>{patientInfo?.lifeQuality && patientInfo?.lifeQuality[0]?.sexuality}</p>
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Beruf / Alltägliche Arbeit</p>
                    <p className={TextLabel}>{patientInfo?.lifeQuality && patientInfo?.lifeQuality[0]?.everydayWork}</p>
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Geistige Tätigkeiten / Konzentration</p>
                    <p className={TextLabel}>{patientInfo?.lifeQuality && patientInfo?.lifeQuality[0]?.concentration}</p>
                  </div>
                  <div className={style.ComplaintsRowContainer}>
                    <p className={TextLabel}>Schlaf</p>
                    <p className={TextLabel}>{patientInfo?.lifeQuality && patientInfo?.lifeQuality[0]?.sleep}</p>
                  </div>
                </div>
              </div>
            </div>
          </div></>
        }
        {documents && documents.length !== 0 && (
          <div>
            <h1 className={GreenHeading}>Dokumente</h1>
            <div className={style.DocumentsContainer}>
              <div className={style.DocumentsRowContainer}>
                <div>
                  <p className={style.DocumentsTextLabel}>Erstellt</p>
                </div>
                <div>
                  <p className={style.DocumentsTextLabel}>Dateiname</p>
                </div>
                <div>
                  <p className={style.DocumentsTextLabel}>Vorschau</p>
                </div>
                <div></div>
              </div>
              {renderDocuments()}
            </div>
          </div>
        )}
        {(!patientInfo.questionnaire && patientInfo?.comment) && (
          <div>
            <h1 className={GreenHeading}>Freitext des patienten</h1>
            <div className={style.FreeTextContainer}>
              <p className={style.FreeText}>{patientInfo?.comment}</p>
            </div>
          </div>
        )}
        <div className={style.spacer}></div>
      </div>)}
    </div>
  );
}
